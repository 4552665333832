import { RootState } from '../../hook/useAppDispatch';

// Selector to get all product third categories
export const selectProduct3rdCategories = (state: RootState) =>
  state.product3rdCategories.product3rdCategories;

// Selector to get loading status for product third categories
export const selectProduct3rdCategoriesLoading = (state: RootState) =>
  state.product3rdCategories.loading;

// Selector to get error message for product third categories
export const selectProduct3rdCategoriesError = (state: RootState) =>
  state.product3rdCategories.error;

// Selector to filter third categories by second category ID
export const selectProduct3rdCategoriesBy2ndCategoryId = 
  (product2ndCategoryId: number) => 
  (state: RootState) => 
    state.product3rdCategories.product3rdCategories.filter(
      (category) => category.product_2nd_category.id === product2ndCategoryId
    );

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface ProductMainCategory {
  id: number;
  name: string;
}

interface ProductMainCategoryState {
  productMainCategories: ProductMainCategory[];
  loading: boolean;
}

const initialState: ProductMainCategoryState = {
  productMainCategories: [],
  loading: false,
};

export const fetchProductMainCategories = createAsyncThunk(
  'productMainCategories/fetchProductMainCategories',
  async () => {
    const { data, error } = await supabase
      .from('product_main_categories') 
      .select('*');

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
);

const productMainCategorySlice = createSlice({
  name: 'productMainCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductMainCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProductMainCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.productMainCategories = action.payload;
      })
      .addCase(fetchProductMainCategories.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default productMainCategorySlice.reducer;

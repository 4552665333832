import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts'; 
import { fetchProduct3rdCategories } from '../../redux/slices/Product3rdCategoriesSlice.ts'; 
import {
  selectProduct3rdCategories,
  selectProduct3rdCategoriesLoading,
} from '../../redux/selector/product3rdCategories.selector.ts'; 

const Product3rdCategories = () => {
  const dispatch = useAppDispatch();
  const product3rdCategories = useSelector(selectProduct3rdCategories);
  const loading = useSelector(selectProduct3rdCategoriesLoading);

  // Fetch product third categories on component mount
  useEffect(() => {
    dispatch(fetchProduct3rdCategories());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Product Third Category', flex: 1 },
    {
      field: 'product_2nd_category',
      headerName: 'Product Second Category',
      flex: 1,
      minWidth: 180,
      renderCell: (params) => {
        return params.row?.product_2nd_category?.name ?? '';
      },
    },
    {
      field: 'product_2nd_category.main_category',
      headerName: 'Main Category',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return params.row?.product_2nd_category?.main_category?.name ?? '';
      },
    },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={product3rdCategories}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default Product3rdCategories;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

// Define interfaces
interface MainCategory {
  id: number;
  name: string;
}

interface Product2ndCategory {
  id: number;
  name: string;
  main_category: MainCategory | null;
}

interface Product2ndCategoryState {
  product2ndCategories: Product2ndCategory[]; // Change this to an array
  loading: boolean;
  error: string | null;
}

// Initial state
const initialState: Product2ndCategoryState = {
  product2ndCategories: [], // Initialize as an empty array
  loading: false,
  error: null,
};

// Thunk to fetch product second categories
export const fetchProduct2ndCategories = createAsyncThunk(
  'product2ndCategories/fetchProduct2ndCategories',
  async () => {
    const { data, error } = await supabase
      .from('product_2nd_categories')
      .select(`
        id, 
        name, 
        main_category:main_category_id (id, name)
      `);

    if (error) {
      throw new Error(error.message);
    }

    const result = data.map((item: any) => ({
      id: item.id,
      name: item.name,
      main_category: item.main_category ? item.main_category : null, // Take the first object from the array or null
    }));

    console.log(result)

    return result as Product2ndCategory[]; 
  }
);

// Slice
const product2ndCategorySlice = createSlice({
  name: 'product2ndCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct2ndCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProduct2ndCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.product2ndCategories = action.payload; // Ensure this is an array
      })
      .addCase(fetchProduct2ndCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch product second categories';
      });
  },
});

export default product2ndCategorySlice.reducer;
import { RootState } from '../../hook/useAppDispatch';

// Selector to get all product second categories
export const selectProduct2ndCategories = (state: RootState) =>
  state.product2ndCategories.product2ndCategories;

// Selector to get loading status
export const selectProduct2ndCategoriesLoading = (state: RootState) =>
  state.product2ndCategories.loading;

// Selector to get error message
export const selectProduct2ndCategoriesError = (state: RootState) =>
  state.product2ndCategories.error;

// Selector to filter second categories by main category ID
export const selectProduct2ndCategoriesByMainCategory = 
  (mainCategoryId: number) => 
  (state: RootState) => 
    state.product2ndCategories.product2ndCategories.filter(
      (category) => category.main_category.id === mainCategoryId
    );

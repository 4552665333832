import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchUsers } from '../../redux/slices/userSlice.ts';
import { selectUsers, selectUsersLoading, selectUsersTotal } from '../../redux/selector/userSelectors.ts';

const Users = () => {
  const dispatch = useAppDispatch();
  const users = useSelector(selectUsers);
  const loading = useSelector(selectUsersLoading);
  const totalUsers = useSelector(selectUsersTotal);

  // Fetch users data based on pagination model (page and pageSize)
  useEffect(() => {
    dispatch(fetchUsers({ page: 1, pageSize: 10 }));
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', flex: 1 },
    { field: 'first_name', headerName: 'First Name', flex: 1 },
    { field: 'last_name', headerName: 'Last Name', flex: 1 },
    { field: 'email', headerName: 'Email', flex: 2, minWidth: 200, renderCell: (params) => (
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
        {params.value}
      </div>
    )},
    { field: 'phone', headerName: 'Phone', flex: 1 },
    { field: 'country', headerName: 'Country', flex: 1 },
    { field: 'businesses', headerName: 'Business', flex: 1, renderCell: (params) => (
      <div>{params.value ? params.value.name : ''}</div>
    )},
    { field: 'job_titles', headerName: 'Job Title', flex: 1, renderCell: (params) => (
      <div>{params.value ? params.value.name : ''}</div>
    )},
    {
      field: 'linkedin_url',
      headerName: 'LinkedIn',
      flex: 1,
      renderCell: (params) => (
        params.value ? (
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none', color: '#0077b5' }} // LinkedIn blue color
          >
            View LinkedIn
          </a>
        ) : null
      ),
    },
    { field: 'is_active', headerName: 'Active', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={users}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        rowCount={totalUsers} 
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
      />
    </div>
  );
};

export default Users;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface User {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  business_id: number;
  job_title_id: number;
  is_active: boolean;
  business?: { id: number; name: string };  // business data
  job_title?: { id: number; title: string }; // job title data
}

interface UserState {
  users: User[];
  loading: boolean;
  total: number;
}

const initialState: UserState = {
  users: [],
  loading: false,
  total: 0,
};

// Async thunk to fetch users
export const fetchUsers = createAsyncThunk(
  'users/fetchUsers',
  async ({ page, pageSize, search }: { page: number; pageSize: number; search?: string }) => {
    let query = supabase
      .from('users')
      .select(`
        *,
        businesses(id, name),
        job_titles(id, name)
      `, { count: 'exact' })
      .range((page - 1) * pageSize, page * pageSize - 1);

    if (search) {
      query = query.ilike('first_name', `%${search}%`).or(`last_name.ilike.%${search}%`);
    }

    const { data, error, count } = await query;

    if (error) {
      throw new Error(error.message);
    }

    return { users: data, total: count };
  }
);

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.users;
        state.total = action.payload.total;
      })
      .addCase(fetchUsers.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default userSlice.reducer;

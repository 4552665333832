import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface BusinessType {
  id: number;
  name: string;
}

interface BusinessTypeState {
  businessTypes: BusinessType[];
  loading: boolean;
}

const initialState: BusinessTypeState = {
  businessTypes: [],
  loading: false,
};

export const fetchBusinessTypes = createAsyncThunk(
  'businessTypes/fetchBusinessTypes',
  async () => {
    const { data, error } = await supabase
      .from('business_types')
      .select(`
        *,
        business_groups(id, name)
      `)

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
);

const businessTypeSlice = createSlice({
  name: 'businessTypes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessTypes.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinessTypes.fulfilled, (state, action) => {
        state.loading = false;
        state.businessTypes = action.payload;
      })
      .addCase(fetchBusinessTypes.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default businessTypeSlice.reducer;

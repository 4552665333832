import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

// Interfaces
interface BusinessClassification {
  id: number;
  name: string;
  description: string;
  business_type: {
    id: number;
    name: string;
  };
}

interface BusinessClassificationState {
  businessClassifications: BusinessClassification[];
  loading: boolean;
  total: number;
}

const initialState: BusinessClassificationState = {
  businessClassifications: [],
  loading: false,
  total: 0,
};

// Async thunk to fetch business classifications
export const fetchBusinessClassifications = createAsyncThunk(
  'businessClassifications/fetchBusinessClassifications',
  async () => {
    let query = supabase
      .from('business_groups')
      .select(`
        *
      `, { count: 'exact' })
      
    const { data, error, count } = await query;

    if (error) {
      throw new Error(error.message);
    }

    return { businessClassifications: data, total: count };
  }
);

// Slice
const businessClassificationSlice = createSlice({
  name: 'businessClassifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessClassifications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinessClassifications.fulfilled, (state, action) => {
        state.loading = false;
        state.businessClassifications = action.payload.businessClassifications;
        state.total = action.payload.total;
      })
      .addCase(fetchBusinessClassifications.rejected, (state) => {
        state.loading = false;
      });
  },
});

// Export Reducer
export default businessClassificationSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface SignageMainApplication {
  id: number;
  name: string;
}

interface SignageSubApplication {
  id: number;
  name: string;
  signage_main_application: SignageMainApplication;
}

interface SignageSubApplicationState {
  signageSubApplications: SignageSubApplication[];
  loading: boolean;
  error: string | null;
}

const initialState: SignageSubApplicationState = {
  signageSubApplications: [],
  loading: false,
  error: null,
};

export const fetchSignageSubApplications = createAsyncThunk(
  'signageSubApplications/fetchSignageSubApplications',
  async () => {
    const { data, error } = await supabase
      .from('signage_sub_applications')
      .select(`
        id, 
        name, 
        signage_main_application:signage_main_application_id (id, name)
      `);

    if (error) {
      throw new Error(error.message);
    }

    return data.map((subApp) => ({
      ...subApp,
      signage_main_application: subApp.signage_main_application[0], // Access the first element
    }));
  }
);

const signageSubApplicationSlice = createSlice({
  name: 'signageSubApplications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignageSubApplications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSignageSubApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.signageSubApplications = action.payload;
      })
      .addCase(fetchSignageSubApplications.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch signage sub applications';
      });
  },
});

export default signageSubApplicationSlice.reducer;

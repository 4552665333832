import React, { useState } from 'react';
import { IconButton, Popover, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CustomActionPopover = ({ actions }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'custom-action-popover' : undefined;

  return (
    <>
      {/* Trigger Button */}
      <IconButton onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      {/* Popover Component */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {actions.map((action, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
            style={action.style || {}}
          >
            {action.label}
          </MenuItem>
        ))}
      </Popover>
    </>
  );
};

export default CustomActionPopover;

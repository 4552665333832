// Selectors
import { RootState } from '../../hook/useAppDispatch';

// Select products from the state
export const selectProducts = (state: RootState) => state.productDetails.products;

// Select loading status for products
export const selectProductsLoading = (state: RootState) => state.productDetails.loading;

// Select total number of products
export const selectProductsTotal = (state: RootState) => state.productDetails.total;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchBrands } from '../../redux/slices/brandSlice.ts';
import { selectBrands, selectBrandsLoading, selectBrandsTotal } from '../../redux/selector/brand.selector.ts';

const BrandsTable = () => {
  const dispatch = useAppDispatch();
  const brands = useSelector(selectBrands);
  const loading = useSelector(selectBrandsLoading);
  const totalBrands = useSelector(selectBrandsTotal);


  // Fetch brands data based on pagination model (page and pageSize)
  useEffect(() => {
    dispatch(fetchBrands());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Name', flex: 1 },
    {
      field: 'website', headerName: 'Website', flex: 2, minWidth: 200, renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'logo_url',
      headerName: 'Logo',
      flex: 1,
      renderCell: (params) => (
        params.value ? (
          <img
            src={params.value}
            alt="Logo"
            style={{ width: 50, height: 50, objectFit: 'contain', borderRadius: '50%' }}
          />
        ) : null // Render nothing if the logo URL is not available
      ),
    },
    {
      field: 'description', headerName: 'Description', flex: 2, minWidth: 200, renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
    { field: 'is_active', headerName: 'Active', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={brands}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        rowCount={totalBrands}
        pagination
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true
          },
        }}
      />
    </div>
  );
};

export default BrandsTable;

import { RootState } from '../../hook/useAppDispatch';

// Select business classifications data
export const selectBusinessClassifications = (state: RootState) => state.businessClassifications.businessClassifications;

// Select loading state for business classifications
export const selectBusinessClassificationsLoading = (state: RootState) => state.businessClassifications.loading;

// Select total count of business classifications
export const selectBusinessClassificationsTotal = (state: RootState) => state.businessClassifications.total;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

// Interfaces
interface ProductMainCategory {
  id: number;
  name: string;
}

interface Product2ndCategory {
  id: number;
  name: string;
  main_category: ProductMainCategory | null; // Ensure it's a single object or null
}

interface Product3rdCategory {
  id: number;
  name: string;
  product_2nd_category: Product2ndCategory;
}

interface Product3rdCategoryState {
  product3rdCategories: Product3rdCategory[];
  loading: boolean;
  error: string | null;
}

const initialState: Product3rdCategoryState = {
  product3rdCategories: [],
  loading: false,
  error: null,
};

// Async Thunk to fetch product 3rd categories
export const fetchProduct3rdCategories = createAsyncThunk(
  'product3rdCategories/fetchProduct3rdCategories',
  async () => {
    const { data, error } = await supabase
      .from('product_3rd_categories')
      .select(`
        *,
        product_2nd_category:product_2nd_category_id (
          id, 
          name, 
          main_category:main_category_id (
            id, 
            name
          )
        )
      `);

    if (error) {
      throw new Error(error.message);
    }

    // Ensure nested relationships match the expected structure
    return data.map((category: any) => ({
      ...category,
      product_2nd_category: {
        ...category.product_2nd_category,
        // Ensure main_category is a single object (not an array)
        main_category: category.product_2nd_category?.main_category || null, // Handle the array case
      },
    }));
  }
);

// Slice
const product3rdCategorySlice = createSlice({
  name: 'product3rdCategories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProduct3rdCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProduct3rdCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.product3rdCategories = action.payload;
      })
      .addCase(fetchProduct3rdCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch Product 3rd Categories';
      });
  },
});

// Export Reducer
export default product3rdCategorySlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';
import { User as SupabaseUser } from '@supabase/auth-js'; 
import { showLoader, hideLoader } from './loaderSlice'; 
import { showToastFailure } from '../../services/toast';

// Define your custom User type based on Supabase User
interface User extends SupabaseUser {
  // You can add any additional fields you need here
}

interface ValidationErrors {
  errorMessage: string;
  field_errors: Record<string, string>;
}

interface AuthState {
  user: User | null;
  error: string | null;
}

const initialState: AuthState = {
  user: null,
  error: null,
};

// Async thunk for signing in with email and password
export const signInWithEmail = createAsyncThunk<
  User, // Return type: User object
  { email: string; password: string }, // Argument type: credentials (email & password)
  { rejectValue: ValidationErrors } // Type for rejected value: validation errors
>(
  'auth/signInWithEmail',
  async (credentials, { dispatch, rejectWithValue }) => {
    // Show the loader before making the API call
    dispatch(showLoader());

    const { email, password } = credentials;
    const { data, error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    // Hide the loader after the API call completes
    dispatch(hideLoader());

    if (error) {
      return rejectWithValue({
        errorMessage: error.message,
        field_errors: {},
      });
    }

    // Return the user object from Supabase, cast to custom User type
    return data.user as User;
  }
);

// Create the auth slice
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signOut: (state) => {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signInWithEmail.fulfilled, (state, { payload }) => {
        state.user = payload;
      })
      .addCase(signInWithEmail.rejected, (state, action) => {
        if (action.payload) {
          showToastFailure(action.payload.errorMessage)
          state.error = action.payload.errorMessage;
        } else {
          showToastFailure(action.error.message || 'An error occurred')
          state.error = action.error.message || 'An error occurred';
        }
      });
  },
});


export const { signOut } = authSlice.actions;

export default authSlice.reducer;

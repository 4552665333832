import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchJobTitles } from '../../redux/slices/jobTitleSlice.ts';
import { selectJobTitles, selectJobTitlesLoading } from '../../redux/selector/jobTitle.selector.ts';

const JobTitlesTable = () => {
  const dispatch = useAppDispatch();
  const jobTitles = useSelector(selectJobTitles);
  const loading = useSelector(selectJobTitlesLoading);

  useEffect(() => {
    dispatch(fetchJobTitles());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Job Title', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={jobTitles}
        columns={columns}
        loading={loading}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default JobTitlesTable;

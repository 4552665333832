import React from 'react';
import RoutesConfig from './routes/Routes.js';
import Loader from './components/Loader.tsx';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <> 
     <ToastContainer/>
      <Loader />
      <RoutesConfig />
    </>
  );
};

export default App;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchSignageMainApplications } from '../../redux/slices/signageMainApplicationSlice.ts';
import {
  selectSignageMainApplications,
  selectSignageMainApplicationsLoading,
} from '../../redux/selector/signageMainApplications.selector.ts';

const SignageMainApplications = () => {
  const dispatch = useAppDispatch();
  const signageMainApplications = useSelector(selectSignageMainApplications);
  const loading = useSelector(selectSignageMainApplicationsLoading);

  // Fetch signage main applications on component mount
  useEffect(() => {
    dispatch(fetchSignageMainApplications());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Signage Main Application', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={signageMainApplications}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default SignageMainApplications;

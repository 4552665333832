import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import { AccountCircle, ExpandLess, ExpandMore } from '@mui/icons-material';
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router-dom';
import Brands from '../page/Brands/page.tsx';
import JobTitles from '../page/JobTitles/page.tsx';
import BusinessTypes from '../page/BusinessType/page.tsx';
import Businesses from '../page/Businesses/page.tsx';
import ProductMainCategories from '../page/ProductMainCategories/page.tsx';
import SignageMainApplications from '../page/SignageMainApplications/page.tsx';
import SignageSubApplications from '../page/SignageSubApplications/page.tsx';
import Product2ndCategories from '../page/Product2ndCategories/page.tsx';
import BusinessClassifications from '../page/BusinessClassifications/page.tsx';
import Users from '../page/Users/page.tsx';
import ProductDetailsPage from '../page/ProductDetails/page.tsx';
import Product3rdCategories from '../page/Product3rdCategories/page.tsx';
import { signOut } from '../redux/slices/authSlice.ts';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    marginLeft: 0,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'space-between',
}));

interface DrawerComponentProps {
  items: { text: string; icon: React.ReactNode; onClick: () => void; subItems?: { text: string; icon: React.ReactNode; onClick: () => void }[] }[];
  header?: React.ReactNode;
  closeOnItemClick?: boolean;
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  items,
  header,
  closeOnItemClick = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null); // For Popover
  const [selectedPage, setSelectedPage] = useState<string | null>(null); // State to track the selected page
  const [selectedSubmenu, setSelectedSubmenu] = useState<string | null>(null); // State to track the selected subitem
  const [openSubmenu, setOpenSubmenu] = useState<{ [key: string]: boolean }>({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const handleItemClick = (onClick: () => void, page: string) => {
    onClick();
    setSelectedPage(page.toLowerCase());
    setSelectedSubmenu(null); // Reset the selected submenu when a main item is clicked
    if (closeOnItemClick) handleDrawerClose();
  };

  const handleSubmenuClick = (onClick: () => void, page: string, submenu: string) => {
    onClick();
    setSelectedSubmenu(submenu); // Track which submenu is selected
    setSelectedPage(page.toLowerCase()); // Keep the main item selected as well
    if (closeOnItemClick) handleDrawerClose();
  };

  const handleSubmenuToggle = (menu: string) => {
    setOpenSubmenu((prev) => ({ ...prev, [menu]: !prev[menu] }));
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget); // Open Popover when avatar is clicked
  };

  const handleClosePopover = () => {
    setAnchorEl(null); // Close Popover
  };

  const handleLogout = () => {
    dispatch(signOut());
    navigate('/login');
    handleClosePopover(); // Close the popover after logout
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'simple-popover' : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: 'black', boxShadow: 'none' }}>
        <Toolbar className="flex">
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ mr: 2, ...(open && { display: 'none' }) }}>
            <MenuIcon />
          </IconButton>
          <div className="flex-1 flex justify-center">
            <img src="/img/viscom-logo-320px.png" alt="Welcome Back" className="w-32 h-auto object-contain" />
          </div>
          <IconButton onClick={handleAvatarClick}>
            <Avatar>
              <AccountCircle />
            </Avatar>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 240,
            boxSizing: 'border-box',
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src="/img/viscom-logo-320px.png" alt="Welcome Back" className="w-32 h-auto object-contain" />
          <IconButton onClick={handleDrawerClose}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {items.map(({ text, icon, onClick, subItems }) => (
            <React.Fragment key={text}>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => handleItemClick(onClick, text)}
                  selected={selectedPage === text.toLowerCase()} // Highlight selected item
                  sx={{
                    backgroundColor: selectedPage === text.toLowerCase() ? 'rgba(255, 255, 255, 0.1)' : 'transparent', // Apply background on selected item
                    '&:hover': {
                      backgroundColor: selectedPage === text.toLowerCase() ? 'rgba(255, 255, 255, 0.1)' : 'rgba(255, 255, 255, 0.2)',
                    },
                  }}
                >
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItemButton>
                {subItems && (
                  <ListItemButton onClick={() => handleSubmenuToggle(text)}>
                    <ListItemIcon>
                      {openSubmenu[text] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemIcon>
                  </ListItemButton>
                )}
              </ListItem>
              {subItems && (
                <Collapse in={openSubmenu[text]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {subItems.map(({ text, icon, onClick }) => (
                      <ListItem key={text} disablePadding>
                        <ListItemButton
                          onClick={() => handleSubmenuClick(onClick, text, text)}
                          selected={selectedSubmenu === text} // Highlight selected subitem
                        >
                          <ListItemIcon>{icon}</ListItemIcon>
                          <ListItemText primary={text} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )}
              <Divider />
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {selectedPage === 'users' && <Users/>}
        {selectedPage === 'brands' && <Brands />}
        {selectedPage === 'job titles' && <JobTitles />}
        {selectedPage === 'business types' && <BusinessTypes />}
        {selectedPage === 'business groups'  && <BusinessClassifications/>}
        {selectedPage === 'businesses' && <Businesses />}
        {selectedPage === 'signage main applications' && <SignageMainApplications />}
        {selectedPage === 'signage sub applications' && <SignageSubApplications />}
        {selectedPage === 'product main categories' && <ProductMainCategories />}
        {selectedPage === 'product 2nd categories' && <Product2ndCategories />}
        {selectedPage === 'product 3rd categories' && <Product3rdCategories />}
        {selectedPage === 'product details' && <ProductDetailsPage />}

      </Main>

      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Button onClick={handleLogout}>Logout</Button>
        </Box>
      </Popover>
    </Box>
  );
};

export default DrawerComponent;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface Business {
  id: number;
  name: string;
  description: string;
  email: string;
  phone: string;
  specialization_id: number;
  website: string;
  google_street_address: string;
  business_type_id: number;
}

interface BusinessState {
  businesses: Business[];
  loading: boolean;
  total: number;
}

const initialState: BusinessState = {
  businesses: [],
  loading: false,
  total: 0,
};

// Async thunk to fetch businesses
export const fetchBusinesses = createAsyncThunk(
  'businesses/fetchBusinesses',
  async ({ page, pageSize, search }: { page: number; pageSize: number; search?: string }) => {
    let query = supabase
      .from('businesses')
      .select(`
        *,
        signage_main_applications(id, name),
        business_types (id, name)
      `, { count: 'exact' })
      .range((page - 1) * pageSize, page * pageSize - 1)

    
    const { data, error, count } = await query;

    if (error) {
      throw new Error(error.message);
    }

    return { businesses: data, total: count };
  }
);

const businessSlice = createSlice({
  name: 'businesses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinesses.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        state.businesses = action.payload.businesses;
        state.total = action.payload.total;
      })
      .addCase(fetchBusinesses.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default businessSlice.reducer;

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; 
import authReducer from './slices/authSlice.ts';
import loaderReducer from './slices/loaderSlice';
import {
  brandReducer,
  businessClassificationReducer,
  businessReducer,
  businessTypesReducer,
  jobTitleReducer,
  product2ndCategoriesReducer,
  product3ndCategoriesReducer,
  productDetailsReducer,
  productMainCategoriesReducer,
  signageMainApplicationReducer,
  signageSubApplicationReducer,
  userReducer,
} from './index.js';

// Combine all reducers
const rootReducer = combineReducers({
  auth: authReducer,
  brands: brandReducer,
  loader: loaderReducer,
  jobsTitle: jobTitleReducer,
  businessTypes: businessTypesReducer,
  businesses: businessReducer,
  productMainCategories: productMainCategoriesReducer,
  signageMainApplication: signageMainApplicationReducer,
  signageSubApplication: signageSubApplicationReducer,
  product2ndCategories: product2ndCategoriesReducer,
  product3rdCategories: product3ndCategoriesReducer,
  businessClassifications: businessClassificationReducer,
  users: userReducer,
  productDetails: productDetailsReducer,
});

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'], // List of reducers you want to persist
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
});

// Create a persistor
export const persistor = persistStore(store);

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface Product {
  id: number;
  product_name: string;
  description: string;
  product_main_category: { id: number; name: string };
  product_2nd_category?: { id: number; name: string };
  product_3rd_categories?: { id: number; name: string }[];
}

interface ProductState {
  products: Product[];
  loading: boolean;
  total: number;
}

const initialState: ProductState = {
  products: [],
  loading: false,
  total: 0,
};

// Async thunk to fetch products with related category data
export const fetchProducts = createAsyncThunk(
  'product_details/fetchProducts',
  async () => {
    let query = supabase
      .from('product_details')
      .select(
        `
        *
      `,
        { count: 'exact' }
      )

    //   product_main_category(id, name),
    //   product_2nd_category(id, name),
    //   product_3rd_categories(id, name)

   
    const { data, error, count } = await query;

    if (error) {
      throw new Error(error.message);
    }

    return { products: data, total: count };
  }
);

const productDetailsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload.products;
        state.total = action.payload.total;
      })
      .addCase(fetchProducts.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default productDetailsSlice.reducer;

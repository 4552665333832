import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchBusinessTypes } from '../../redux/slices/businessTypeSlice.ts';
import { selectBusinessTypes, selectBusinessTypesLoading } from '../../redux/selector/businessType.selector.ts';

const BusinessTypes = () => {
  const dispatch = useAppDispatch();
  const businessTypes = useSelector(selectBusinessTypes);
  const loading = useSelector(selectBusinessTypesLoading);

  // Fetch business types on component mount
  useEffect(() => {
    dispatch(fetchBusinessTypes());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Business Type', flex: 1 },
    { field: 'business_groups', headerName: 'Business Groups', flex: 1, renderCell: (params) => (
      <div>{params?.value ? params.value.name : ''}</div>
    )},
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={businessTypes}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default BusinessTypes;

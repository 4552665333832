import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchBusinessClassifications } from '../../redux/slices/businessClassificationSlice.ts';
import {
  selectBusinessClassifications,
  selectBusinessClassificationsLoading,
  selectBusinessClassificationsTotal,
} from '../../redux/selector/ businessClassification.selector.ts';

const BusinessClassifications = () => {
  const dispatch = useAppDispatch();
  const businessClassifications = useSelector(selectBusinessClassifications);
  const loading = useSelector(selectBusinessClassificationsLoading);
  const total = useSelector(selectBusinessClassificationsTotal);

  // Fetch business classifications on component mount
  useEffect(() => {
    dispatch(fetchBusinessClassifications()); // Adjust page and pageSize as needed
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Classification Name', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={businessClassifications}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        rowCount={total}
        />
    </div>
  );
};

export default BusinessClassifications;

// src/components/SignageSubApplications.tsx
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchSignageSubApplications } from '../../redux/slices/signageSubApplicationSlice.ts';
import {
  selectSignageSubApplications,
  selectSignageSubApplicationsLoading,
} from '../../redux/selector/signageSubApplications.selector.ts';

const SignageSubApplications = () => {
  const dispatch = useAppDispatch();
  const signageSubApplications = useSelector(selectSignageSubApplications);
  const loading = useSelector(selectSignageSubApplicationsLoading);

  // Fetch signage sub applications on component mount
  useEffect(() => {
    dispatch(fetchSignageSubApplications());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Signage Sub Application', flex: 1 },
    {
      field: 'signage_main_application',
      headerName: 'Main Application',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        return  params.row?.name ?? '';
      }
    },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={signageSubApplications}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default SignageSubApplications;

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import {
    selectProducts,
    selectProductsLoading,
    selectProductsTotal,
} from '../../redux/selector/productDetails.selector.ts';
import { fetchProducts } from '../../redux/slices/productDetailsSlice.ts';
import { Tooltip } from '@mui/material';

const ProductDetailsPage = () => {
    const dispatch = useAppDispatch();
    const products = useSelector(selectProducts);
    const loading = useSelector(selectProductsLoading);
    const total = useSelector(selectProductsTotal);

    // Fetch products on component mount
    useEffect(() => {
        dispatch(fetchProducts());
    }, [dispatch]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'product_name', headerName: 'Product Name', flex: 1 },
        {
            field: 'description', headerName: 'Description', flex: 1,
            renderCell: (params) => (
                <Tooltip title={params.value || ''} placement="top">
                    <span
                        style={{
                            cursor: 'pointer',
                            color: 'black'
                        }}
                    >
                        {params.value}
                    </span>
                </Tooltip>
            ),
        },
        {
            field: 'product_main_category',
            headerName: 'Main Category',
            flex: 1,
            renderCell: (params) => params.row.product_main_category?.name || '',
        },
        {
            field: 'product_2nd_category',
            headerName: '2nd Category',
            flex: 1,
            renderCell: (params) => params.row.product_2nd_category?.name || '',
        },
        {
            field: 'product_3rd_categories',
            headerName: '3rd Categories',
            flex: 1,
            renderCell: (params) =>
                params.row.product_3rd_categories
                    ?.map((cat) => cat.name)
                    .join(', ') || '',
        },
    ];

    return (
        <div style={{ height: '85vh', width: '100%' }}>
            <h1>Product Details</h1>
            <DataGrid
                rows={products}
                columns={columns}
                loading={loading}
                getRowId={(row) => row.id}
                slots={{ toolbar: GridToolbar }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
                rowCount={total}
            />
        </div>
    );
};

export default ProductDetailsPage;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchBusinesses } from '../../redux/slices/businessSlice.ts';
import { selectBusinesses, selectBusinessesLoading, selectBusinessesTotal } from '../../redux/selector/business.selector.ts';
import CustomActionPopover from '../../components/CustomActionPopover.tsx';

const Businesses = () => {
  const dispatch = useAppDispatch();
  const businesses = useSelector(selectBusinesses);
  const loading = useSelector(selectBusinessesLoading);
  const totalBusinesses = useSelector(selectBusinessesTotal);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    dispatch(
      fetchBusinesses({
        page: paginationModel.page + 1,
        pageSize: paginationModel.pageSize,
        search: searchQuery, // Include search query in the API request
      })
    );
  }, [dispatch, paginationModel, searchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setPaginationModel({ ...paginationModel, page: 0 }); // Reset to the first page on new search
  };

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      minWidth: 250,
      renderCell: (params) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {params.value}
        </div>
      ),
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 200,
      align: 'left',
      renderCell: (params) => (
        <a
          href={`mailto:${params.value}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1976d2', textDecoration: 'none' }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'phone',
      headerName: 'Phone',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'signage_main_applications',
      headerName: 'Specialization',
      flex: 1,
      minWidth: 120,
      valueGetter: (params: any) => {
        return params?.name
      },
      renderCell: (params) => params.value || '',
    },
    {
      field: 'website',
      headerName: 'Website',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <a
          href={params.value}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1976d2', textDecoration: 'none' }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'google_street_address',
      headerName: 'Address',
      flex: 2,
      minWidth: 300,
      renderCell: (params) => (
        <a
          href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(params.value)}`}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: '#1976d2', textDecoration: 'none' }}
        >
          {params.value}
        </a>
      ),
    },
    {
      field: 'business_types',
      headerName: 'Business Type',
      flex: 1,
      minWidth: 150,
      valueGetter: (params: any) => {
       return params?.name || ''
      },
      renderCell: (params) => params.value || '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <CustomActionPopover
          actions={[
            {
              label: 'View',
              onClick: () => {
                console.log('Viewing details for:', params.row);
                // Trigger View action, e.g., open modal or drawer
              },
            },
            {
              label: 'Edit',
              onClick: () => {
                console.log('Editing row:', params.row);
                // Trigger Edit action, e.g., open edit form
              },
            },
            {
              label: 'Delete',
              onClick: () => {
                console.log('Deleting row:', params.row);
                // Trigger Delete action, e.g., show confirmation dialog
              },
              style: { color: 'red' }, // Optional custom style
            },
          ]}
        />
      ),
    },
  ];

  return (
    <div
      style={{
        height: 'calc(100vh - 100px)',
        width: '100%',
        overflowX: 'auto',
      }}
    >
      <div style={{ minWidth: 1200 }}>
        <div style={{ marginBottom: 16 }}>
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
            style={{
              width: '100%',
              padding: '8px',
              border: '1px solid #ccc',
              borderRadius: '4px',
            }}
          />
        </div>
        <DataGrid
          rows={businesses}
          columns={columns}
          loading={loading}
          getRowId={(row) => row.id}
          rowCount={totalBusinesses}
          pagination
          paginationMode="server"
          onPaginationModelChange={setPaginationModel}
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
        />
      </div>
    </div>
  );
};

export default Businesses;

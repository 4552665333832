import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts';
import { fetchProductMainCategories } from '../../redux/slices/productMainCategorySlice.ts';
import {
  selectProduct2ndCategories,
  selectProduct2ndCategoriesLoading
} from '../../redux/selector/selectProduct2ndCategories.selector.ts';

const ProductMainCategories = () => {
  const dispatch = useAppDispatch();
  const product2ndCategories = useSelector(selectProduct2ndCategories);
  const loading = useSelector(selectProduct2ndCategoriesLoading);

  // Fetch product main categories on component mount
  useEffect(() => {
    dispatch(fetchProductMainCategories());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Product Main Category', flex: 1 },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={product2ndCategories}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default ProductMainCategories;

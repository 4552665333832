import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useAppDispatch } from '../../hook/useAppDispatch.ts'; // Adjust the path as needed
import { fetchProduct2ndCategories } from '../../redux/slices/product2ndCategorySlice.ts'; 
import {
  selectProduct2ndCategories,
  selectProduct2ndCategoriesLoading,
} from '../../redux/selector/selectProduct2ndCategories.selector.ts';
const Product2ndCategories = () => {
  const dispatch = useAppDispatch();
  const product2ndCategories = useSelector(selectProduct2ndCategories);
  console.log('product2ndCategories', product2ndCategories)
  const loading = useSelector(selectProduct2ndCategoriesLoading);

  // Fetch product second categories on component mount
  useEffect(() => {
    dispatch(fetchProduct2ndCategories());
  }, [dispatch]);

  const columns: GridColDef[] = [
    { field: 'name', headerName: 'Product Second Category', flex: 1 },
    {
      field: 'main_category',
      headerName: 'Main Category',
      flex: 1,
      minWidth: 120,
      renderCell: (params) => {
        console.log(params.row);
        return params.row?.main_category?.name ?? 'N/A';
      },
    },
  ];

  return (
    <div style={{ height: '85vh', width: '100%' }}>
      <DataGrid
        rows={product2ndCategories}
        columns={columns}
        loading={loading}
        getRowId={(row) => row.id}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </div>
  );
};

export default Product2ndCategories;

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginPage from '../page/Login/page.jsx';
import DrawerComponent from '../components/DrawerComponent.tsx';
import { selectIsAuthenticated } from '../redux/selector/auth.selector.js';
import PrivateRoute from './PrivateRoutes.js';
import {
  Business,
  Work,
  BrandingWatermark,
  Store,
  Category,
  Layers,
  Apps,
  Group,
  Home,
  ViewList,
  BusinessCenter
} from '@mui/icons-material';

const RoutesConfig = () => {
  const isAuthenticated = useSelector(selectIsAuthenticated);

  const items = [
    {
      text: 'Details',
      icon: <Home />,
      onClick: () => console.log('Navigating to Details...'),
      subItems: [
        {
          text: 'Business Groups',
          icon: <BusinessCenter />,
          onClick: () => console.log('Navigating to Business Groups...'),
        },
        {
          text: 'Business Types',
          icon: <Business />,
          onClick: () => console.log('Navigating to Business Types...'),
        },
        {
          text: 'Job Titles',
          icon: <Work />,
          onClick: () => console.log('Navigating to Job Titles...'),
        },
      ],
    },
    {
      text: 'Businesses',
      icon: <Store />,
      onClick: () => console.log('Navigating to Businesses...'),
    },
    {
      text: 'Products',
      icon: <Category />,
      onClick: () => console.log('Navigating to Products...'),
      subItems: [
        {
          text: 'Product Main Categories',
          icon: <Category />,
          onClick: () => console.log('Navigating to Product Main Categories...'),
        },
        {
          text: 'Product 2nd Categories',
          icon: <Layers />,
          onClick: () => console.log('Navigating to Product 2nd Categories...'),
        },
        {
          text: 'Product 3rd Categories',
          icon: <Layers />,
          onClick: () => console.log('Navigating to Product 3rd Categories...'),
        },
        {
          text: 'Product Details',
          icon: <Layers />,
          onClick: () => console.log('Navigating to Product Details...'),
        },
      ],
    },
    {
      text: 'Signage',
      icon: <Apps />,
      onClick: () => console.log('Navigating to Signage Applications...'),
      subItems: [
        {
          text: 'Signage Main Applications',
          icon: <Apps />,
          onClick: () => console.log('Navigating to Signage Main Applications...'),
        },
        {
          text: 'Signage Sub Applications',
          icon: <ViewList />,
          onClick: () => console.log('Navigating to Signage Sub Applications...'),
        },
      ],
    },
    {
      text: 'Users',
      icon: <Group />,
      onClick: () => console.log('Navigating to Users...'),
    },
    {
      text: 'Brands',
      icon: <BrandingWatermark />,
      onClick: () => console.log('Navigating to Brands...'),
    },
  ];

  return (
    <Routes>
      {/* Public Route: Login Page */}
      <Route path="/login" element={<LoginPage />} />

      {/* Public Route: Home Page with DrawerComponent */}
      <Route path="/home" element={<DrawerComponent items={items} />} />

      {/* Protected Route: Drawer Component, only accessible if authenticated */}
      <Route
        path="/dashboard"
        element={
          isAuthenticated ? <PrivateRoute element={<DrawerComponent items={items} />} /> : <Navigate to="/login" />
        }
      />

      {/* Catch-all Route: Redirect to Home if authenticated or Login if not */}
      <Route path="*" element={<Navigate to={isAuthenticated ? '/home' : '/login'} replace />} />
    </Routes>
  );
};

export default RoutesConfig;

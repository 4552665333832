import { RootState } from '../../hook/useAppDispatch';

// Select users from the state
export const selectUsers = (state: RootState) => state.users.users;

// Select loading status for users
export const selectUsersLoading = (state: RootState) => state.users.loading;

// Select total number of users
export const selectUsersTotal = (state: RootState) => state.users.total;

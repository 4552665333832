// src/redux/slices/brandSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client'; 

interface Brand {
  id: number;
  name: string;
  website: string;
  logo: string;
  description: string;
  is_active: boolean;
}

interface BrandState {
  brands: Brand[];
  loading: boolean;
  total: number;
}

const initialState: BrandState = {
  brands: [],
  loading: false,
  total: 0,
};

export const fetchBrands = createAsyncThunk(
  'brands/fetchBrands',
  async () => {
    const { data, error, count } = await supabase
      .from('brands')
      .select('*', { count: 'exact' })

    if (error) {
      throw new Error(error.message);
    }

    return { brands: data, total: count };
  }
);

const brandSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBrands.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchBrands.fulfilled, (state, action) => {
        state.loading = false;
        state.brands = action.payload.brands;
        state.total = action.payload.total;
      })
      .addCase(fetchBrands.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default brandSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface SignageMainApplication {
  id: number;
  name: string;
}

interface SignageMainApplicationState {
  signageMainApplications: SignageMainApplication[];
  loading: boolean;
}

const initialState: SignageMainApplicationState = {
  signageMainApplications: [],
  loading: false,
};

export const fetchSignageMainApplications = createAsyncThunk(
  'signageMainApplications/fetchSignageMainApplications',
  async () => {
    const { data, error } = await supabase
      .from('signage_main_applications') // Ensure this matches your Supabase table name
      .select('*');

    if (error) {
      throw new Error(error.message);
    }

    return data;
  }
);

const signageMainApplicationSlice = createSlice({
  name: 'signageMainApplications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSignageMainApplications.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSignageMainApplications.fulfilled, (state, action) => {
        state.loading = false;
        state.signageMainApplications = action.payload;
      })
      .addCase(fetchSignageMainApplications.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default signageMainApplicationSlice.reducer;

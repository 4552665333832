// src/redux/slices/jobTitleSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { supabase } from '../../client';

interface JobTitle {
  id: number;
  name: string;
}

interface JobTitleState {
  jobTitles: JobTitle[];
  loading: boolean;
  total: number;
}

const initialState: JobTitleState = {
  jobTitles: [],
  loading: false,
  total: 0,
};

// Async thunk to fetch job titles
export const fetchJobTitles = createAsyncThunk(
  'jobTitles/fetchJobTitles',
  async () => {
    const { data, error, count } = await supabase
      .from('job_titles')
      .select('*', { count: 'exact' })

    if (error) {
      throw new Error(error.message);
    }

    return { jobTitles: data, total: count };
  }
);

const jobTitleSlice = createSlice({
  name: 'jobTitles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobTitles.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJobTitles.fulfilled, (state, action) => {
        state.loading = false;
        state.jobTitles = action.payload.jobTitles;
        state.total = action.payload.total;
      })
      .addCase(fetchJobTitles.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default jobTitleSlice.reducer;